import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Grid, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Editor } from "@tinymce/tinymce-react";




function FormTab() {
  const [Title, setTitle] = useState('');
  const [Location, setLocation] = useState('');
  const [Category, setCategory] = useState('');
  const [HowMuch, setHowMuch] = useState('');
  const [Time, setTime] = useState('');
  const [Date, setDate] = useState('');
  const [selectedTown, setSelectedTown] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [description, setDesc] = useState('');
  const [googlemap, setGoogleMap] = useState('');

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const [townError, setTownError] = useState('');

  const navigate = useNavigate();

  const submitProduct = async () => {
    const userID = JSON.parse(localStorage.getItem('user')).result._id;
    const baseUrl = `${process.env.REACT_APP_BACKEND}/api/add-events`;
    const data = {
      Title: Title,
      Location: Location,
      Category: Category,
      HowMuch: HowMuch,
      Time: Time,
      Date: Date,
      description: description,
      selectedTown: selectedTown,
      googlemap:googlemap,
      userID: userID,
    };
    const formData = new FormData();
    for (let name in data) {
      formData.append(name, data[name]);
    }
    formData.append('file', file);
    try {
      const result = await axios({
        method: 'post',
        url: baseUrl,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (result) {
        console.log(result);
        toast.success('Data saved successfully'); // Display success notification
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error saving data:', error);
      toast.error('Error saving data'); // Display error notification
    }
  };

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!selectedTown) {
      setTownError('Please select a town.'); // Set an error message
    } else {
      setTownError(''); // Clear the error message if the field is not empty
      submitProduct(); // Call the submitProduct function here
    }
  };
  const categoryOptions = [
    "All Categories",
    "Arts",
    "Outdoor",
    "Music",
    "Festival",
    "Other (Craft Beer)",
    "Family/Children",
    "Sports",
    "Other",
    "Theater",
    "Food/Wine",
    "Historical",
    "4th of July",
    "Other (Networking)",
    "Entertainment",
    "Festival (Fireworks)"
  ];
  const capeCodTownNames = [
    'Barnstable',
    'Bourne',
    'Brewster',
    'Chatham',
    'Dennis',
    'Eastham',
    'Falmouth',
    'Harwich',
    'Mashpee',
    'Orleans',
    'Provincetown',
    'Sandwich',
    'Truro',
    'Wellfleet',
    'Yarmouth',
  ];
  
  return (
    <Container>
      <Typography variant="h5"></Typography>
      <ToastContainer />
      <form className='eventform' onSubmit={handleSubmit}>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
  <FormControl fullWidth={true}>
    <DatePicker
      selected={Date}
      onChange={(newDate) => setDate(newDate)}
      dateFormat="MM/dd/yyyy"
      className="form-control"
      placeholderText="Date" // Placeholder text for the date field
    />
  </FormControl>
</Grid>





          <Grid item xs={12} sm={6}>
            <TextField
              label="Title"
              fullWidth
              variant="outlined"
              value={Title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Location"
              fullWidth
              variant="outlined"
              value={Location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
  <FormControl fullWidth variant="outlined">
    <InputLabel>Town</InputLabel>
    <Select
      label="Cape Cod Town"
      value={selectedTown}
      onChange={(e) => {
        setSelectedTown(e.target.value);
        setTownError(''); // Clear the error message when a selection is made
      }}
    >
      {capeCodTownNames.map((townName, index) => (
        <MenuItem key={index} value={townName}>
          {townName}
        </MenuItem>
      ))}
    </Select>
    {townError && <span className="error-message">{townError}</span>}
  </FormControl>
</Grid>

          <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined">
              <InputLabel>Category</InputLabel>
              <Select
                label="Category"
                value={Category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {categoryOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Entry fee"
              fullWidth
              variant="outlined"
              value={HowMuch}
              onChange={(e) => setHowMuch(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
        <InputLabel htmlFor="name-input">Event Description</InputLabel>

        <Editor
              textareaName="product Description"
              value={description} 
              //onEditorChange={(e)=>SetDescription(e.target.value)} 
              onEditorChange={(newValue, editor) => setDesc(newValue)}
              init={{
                  height: 500,
                  menubar: true,
                  plugins: 'autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount',

                  toolbar: 'undo redo | formatselect | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
              }}
          />
        </Grid>
        <Grid item xs={12}>
            <TextField
              label="Google Map Link"
              fullWidth
              variant="outlined"
              value={googlemap}
              onChange={(e) => setGoogleMap(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Time"
              fullWidth
              variant="outlined"
              value={Time}
              onChange={(e) => setTime(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
  <label htmlFor="thumbnail-upload">
    <Button
      variant="contained"
      color="primary"
      component="span"
      fullWidth
      style={{ cursor: 'pointer' }} // Change cursor to pointer
    >
      Upload Featured Image
    </Button>
  </label>
  <input
    accept="image/*"
    id="thumbnail-upload"
    type="file"
    onChange={saveFile}
    style={{ display: 'none' }} // Hide the native file input
  />
  {file && (
    <img
      src={URL.createObjectURL(file)}
      alt="Selected Image"
      style={{ marginTop: '10px', maxWidth: '100%' }}
    />
  )}
</Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" fullWidth type="submit">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default FormTab;
