import React from 'react';
import './Modal.css';
import Dummy from '../assets/capecodeimg.jpg';
import ReactHtmlParser from 'react-html-parser';

function Modal({ event, onClose }) {
  if (!event) return null;

  const convertUrl = (document) => {
    const startsWithHttpOrHttps = /^https?:/.test(document);
    if (startsWithHttpOrHttps) {
      return document;
    } else {
      return `${process.env.REACT_APP_BACKEND}/uploads/events/${document}`;
    }
  };

  const getDirectionsUrl = `https://www.google.com/maps/search/?api=1&query=${event.Location}`;
  const getDirectionsUrltown = `https://www.google.com/maps/search/?api=1&query=${event.selectedTown}`;

  const modalUrl = window.location.href; // Get the current URL

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal">
        <div className="modal-header">
          <div className="modal-date center-align">
            {new Date(event.Date).toLocaleDateString()}
          </div>
          <button onClick={onClose} className="modal-close-button">
            &times;
          </button>
        </div>
        <h1 className="modal-title center-align">{event.Title}</h1>

        <div className="modal-image">
          {(event?.document || event['Image URL']) ? (
            <img
              className="modal-img"
              src={convertUrl((event?.document || event['Image URL']).replace(/"/g, ''))}
              alt="Event"
            />
          ) : (
            <img
              className="modal-img"
              src={Dummy}
              alt="Event"
            />
          )}
        </div>
        <p>{ReactHtmlParser(event.description)}</p>
        <div className="modal-content">
          <div className="modal-column">
            <div className="modal-content-row">
              {event.Location && (
                <div className="modal-icon">
                  <i className="fa fa-map-marker"></i>
                </div>
              )}
              {event.Location && (
                <p>
                  {event.Location} (<a href={getDirectionsUrl} target="_blank" rel="noopener noreferrer">Get Directions</a>)
                </p>
              )}
            </div>
            <div className="modal-content-row">
              {event.selectedTown && (
                <div className="modal-icon">
                  <i className="fa fa-map-marker"></i>
                </div>
              )}
              {event.selectedTown && (
                <p>
                  {event.selectedTown} (<a href={getDirectionsUrltown} target="_blank" rel="noopener noreferrer">Get Directions</a>)
                </p>
              )}
            </div>
            <div className="modal-content-row">
              {event.Category && (
                <div className="modal-icon">
                  <i className="fa fa-tags"></i>
                </div>
              )}
              {event.Category && <p>{event.Category}</p>}
            </div>
          </div>
          <div className="modal-column">
            <div className="modal-content-row">
              {event.HowMuch && (
                <div className="modal-icon">
                  <i className="fa fa-money"></i>
                </div>
              )}
              {event.HowMuch && <p>{event.HowMuch}</p>}
            </div>
            <div className="modal-content-row">
              {event.Time && (
                <div className="modal-icon">
                  <i className="fa fa-clock-o"></i>
                </div>
              )}
              {event.Time && <p>{event.Time}</p>}
            </div>
            <div className="modal-content-row">
  {event.date && (
    <div className="modal-icon">
      <i className="fa fa-calendar"></i>
    </div>
  )}
  <i className="fa fa-calendar"></i>{new Date(event.Date).toLocaleDateString()}
  {event.Title && (
    <a
      href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event.Title)}&dates=${encodeURIComponent(event.Date)}&details=${encodeURIComponent(event.Description)}&location=${encodeURIComponent(event.Location)}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <small>Add to Calendar</small>
    </a>
  )}
</div>



           

          </div>
        </div>
        {/* {event.Location && event.selectedTown && event.Category && event.HowMuch && event.Time && (
          <hr className="modal-hr" />
        )} */}
        <hr className="modal-hr" />

<div className="modal-content-row">
  {event.googlemap && (
    <div className="modal-icon">
    </div>
  )}
  {event.googlemap && (
    <p>
      <br />
      <iframe
        title="Google Map"
        width="600"
        height="300"
        src={event.googlemap} // Provide the Google Maps location link here
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
      ></iframe>
    </p>
  )}
</div>
<hr className="modal-hr" />

        <div className="modal-buttons">
        <button
  className="modal-button"
  onClick={() => {
    // Copy the modal URL to the clipboard
    const eventUrl = `${window.location.origin}?eventId=${event._id}`;
    navigator.clipboard.writeText(eventUrl)
      .then(() => {
        alert('Link copied to clipboard');
      })
      .catch((error) => {
        console.error('Error copying link: ', error);
      });
  }}
>
  Copy Link
</button>

<button
  className="modal-button"
  onClick={() => {
    // Construct the mailto link with the subject and body
    const subject = `Invitation to Event: ${event.Title}`;
    const body = `Check out this event: ${modalUrl}`;
    const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the default email client
    window.open(mailtoLink);
  }}
>
  Invite Via Email
</button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
