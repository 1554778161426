import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'; // Import the ToastContainer and toast

import 'react-toastify/dist/ReactToastify.css'; // Import the styles for react-toastify

const DataTableTab = () => {
  const [events, setEvents] = useState([]);
  const [searchText, setSearchText] = useState('');

  // Fetch events initially
  useEffect(() => {
    fetchEvents();
  }, []);

  // Filter events based on the search text
  const filteredEvents = events.filter((event) =>
    event.Title.toLowerCase().includes(searchText.toLowerCase())
  );

  // Function to fetch events from the backend API
  const fetchEvents = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND}/api/events`)
      .then((response) => {
        const data = response.data;
        console.log('Fetched events:', data);
        if (Array.isArray(data)) {
          // Ensure each event has a unique 'id' property
          const eventsWithIds = data.map((event, index) => ({ ...event, id: index + 1 }));
          setEvents(eventsWithIds);
        }
      })
      .catch((error) => console.error('Error fetching events:', error));
  };

  // Define the handleDelete function
  const handleDelete = (id) => {
    // Implement the delete functionality for the selected event
    axios
      .delete(`${process.env.REACT_APP_BACKEND}/api/delete-event/${id}`)
      .then(() => {
        // After successful deletion, fetch the updated list of events
        fetchEvents();
        console.log(`Event with ID ${id} deleted successfully.`);
        // Show a success toast
        toast.success('Data Deleted successfully'); // Display success notification
      })
      .catch((error) => {
        console.error(`Error deleting event with ID ${id}: ${error}`);
        // Show an error toast
        toast.error(`Error deleting event with ID ${id}: ${error}`); // Display error notification
      });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'Title', headerName: 'Title', width: 450 },
    { field: 'Location', headerName: 'Location', width: 350 },
    {
      field: 'Date',
      headerName: 'Date',
      width: 200,
      // valueFormatter: (params) => {
      //   return format(new Date(params.value), 'yyyy-MM-dd');
      // },
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 100,
      renderCell: (params) => (
        <DeleteIcon
          onClick={() => handleDelete(params.row._id)}
          style={{ cursor: 'pointer' }}
        />
      ),
    },
  ];

  return (
    <div className="datatable-container">
      <ToastContainer /> {/* Add the ToastContainer component */}
      <input
        type="text"
        placeholder="Search by title"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={filteredEvents}
          columns={columns}
          pageSize={5}
          className="custom-datagrid"
          disableClickEventBubbling={true} // Disable click event bubbling to prevent row selection
        />
      </div>
    </div>
  );
};

export default DataTableTab;
