import React, { useState,useEffect,useRef } from 'react';
import { Link , useNavigate} from "react-router-dom";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const Login = () => {

  const[lemail,SetLEmail]=useState("");
    const[Lpassword,SetLPassword]=useState("");
    const[fname,SetFullname]=useState("");
    const[email,SetEmail]=useState("");
    const[password,SetPassword]=useState("");
    const[repassword,Setrepassword]=useState("");
    const navigate = useNavigate();

    const histoty = useNavigate()
    const formRef= useRef();

    
useEffect(()=>{
  const auth=localStorage.getItem('user')
  if(auth){
      histoty('/dashboard')
  }
}
)
  const handleSubmit = async (e,formRef)=>{
    e.preventDefault();
    // const form = document.forms.login
    const obj = {
        email:formRef.current.email.value,
        password:formRef.current.password.value,
    }
    
    try {
        const rawResponse = await fetch(`${process.env.REACT_APP_BACKEND}/api/login`, {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj)
        });

        if(!rawResponse.ok) {
            throw new Error(rawResponse.statusText);
        }
        const content = await rawResponse.json();
        localStorage.setItem("user",JSON.stringify(content));
        if(content.status == 'success'){
            histoty('/dashboard')
        }
    } catch (error) {
        console.log(error)
        alert('invalid user nmae or pasword')
    }


}
  return (
    <>
    {/* <div className='hold-transition login-page'>
<div className="login-box">
  <div className="card card-outline card-primary">

    <div className="card-body">
      <p className="login-box-msg">Sign in to start your session</p>
      <form ref={formRef} onSubmit={(e) => handleSubmit(e,formRef)}>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" name='email' placeholder="Email"    />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-envelope" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="password" className="form-control" name='password' placeholder="Password"  />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-lock" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-8">
                                                <div className="icheck-primary">
                                                    <input type="checkbox" id="remember" />
                                                    <label htmlFor="remember">
                                                        Remember Me
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                            </div>
                                        </div>
                                        </form>
      
    </div>
  </div>
</div>
</div> */}



















<Container
      maxWidth="sm"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
        ref={formRef} onSubmit={(e) => handleSubmit(e,formRef)}
      >
        <TextField
          label="Username"
          variant="outlined"
          className="form-control" 
          name='email' 
          placeholder="Email"  
          required
          fullWidth
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          className="form-control" 
          name='password' 
          placeholder="Password" 
          required
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          type="submit"
        >
          Login
        </Button>
      </form>
    </Container>





</>
  )
}

export default Login