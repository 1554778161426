import React, { useState,useEffect,useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Dummy from './assets/capecodeimg.jpg';
import 'font-awesome/css/font-awesome.min.css';
import PrivateRoute from './PrivateRoute';
import Home from './Components/Home';
import Login from './Components/Login';
import Dashboard from './Components/Dashboard';

function App() {
  const auth=localStorage.getItem('user')

  const [cart,setCart] = useState([])
  useEffect(() => {
    if(localStorage.getItem('cart')!=null && localStorage.getItem('cart') != 'null'){
      setCart(JSON.parse(localStorage.getItem('cart')).cart)
    }
  },[])

   return (
    <>
    <div className="App">
        <BrowserRouter>
              <Routes>
              <Route exact path="/" element={<Home/>}/>
              <Route exact path="/login" element={<Login/>}/>



              <Route element={<PrivateRoute/>}>
              <Route exact path="/dashboard" element={<Dashboard/>}/>

                
                
                
                </Route>


             </Routes>
        </BrowserRouter>     
    </div>
    </>
  );
}

export default App;