import React, { useState } from 'react';
import { Tabs, Tab, Paper } from '@mui/material';
import FormTab from './Formtab';
import DataTableTab from './DatatableTab';

function Dashboard() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Paper>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Add New Events" />
        <Tab label="Events List" />
      </Tabs>

      {/* Render the content based on the selected tab */}
      {selectedTab === 0 && <FormTab/>}
      {selectedTab === 1 && <DataTableTab />}
    </Paper>
  );
}

export default Dashboard;
