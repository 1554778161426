import React, { useState, useEffect } from 'react';
import '../App.css';
import Dummy from '../assets/capecodeimg.jpg';
import 'font-awesome/css/font-awesome.min.css';
import Modal from './Modal'; // Import the Modal component
import { useLocation } from 'react-router-dom'; // Import the useLocation hook

function Home() {
  const [events, setEvents] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTown, setSelectedTown] = useState(''); // New state for selected town
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [uniqueEventIds, setUniqueEventIds] = useState(new Set());
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Function to open the modal with the selected event
  const openModal = (event) => {
    setSelectedEvent(event);
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedEvent(null);
  };

  useEffect(() => {
    // Fetch events from the backend API
    fetch(`${process.env.REACT_APP_BACKEND}/api/events`)
      .then(response => response.json())
      .then(data => {
        console.log('Fetched events:', data);
        if (Array.isArray(data)) {
          // Sort events by date in descending order
          data.sort((a, b) => new Date(b.Date) - new Date(a.Date));
          setEvents(data);
          setFilteredEvents(data); // Show all events by default
        }
      })
      .catch(error => console.error('Error fetching events:', error));
  }, []);

  // Function to filter events based on the selected location, date, category, and search query
  const filterEvents = () => {
    let filtered = events;

    // Filter by location
    if (selectedLocation) {
      filtered = filtered.filter(event => event.Location === selectedLocation);
      setSelectedDate(''); // Reset the date filter
      setSelectedCategory(''); // Reset the category filter
      setSearchQuery(''); // Reset the search query
      setSelectedTown(''); // Reset the town filter
    }

    // Filter by date
    if (selectedDate) {
      filtered = filtered.filter(event => event.Date === selectedDate);
      setSelectedLocation(''); // Reset the location filter
      setSelectedCategory(''); // Reset the category filter
      setSearchQuery(''); // Reset the search query
      setSelectedTown(''); // Reset the town filter
    }

    // Filter by category
    if (selectedCategory) {
      filtered = filtered.filter(event => event.Category === selectedCategory);
      setSelectedLocation(''); // Reset the location filter
      setSelectedDate(''); // Reset the date filter
      setSearchQuery(''); // Reset the category filter
      setSelectedTown(''); // Reset the town filter
    }

    // Filter by search query (event title)
    if (searchQuery) {
      filtered = filtered.filter(event =>
        event.Title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setSelectedLocation(''); // Reset the location filter
      setSelectedDate(''); // Reset the date filter
      setSelectedCategory(''); // Reset the category filter
      setSelectedTown(''); // Reset the town filter
    }

    // Filter by town
    if (selectedTown) {
      filtered = filtered.filter(event => event.selectedTown === selectedTown);
      setSelectedLocation(''); // Reset the location filter
      setSelectedDate(''); // Reset the date filter
      setSelectedCategory(''); // Reset the category filter
      setSearchQuery(''); // Reset the search query
    }

    // Filter out duplicate events based on unique event IDs
    filtered = filtered.filter(event => !uniqueEventIds.has(event._id));
    return filtered;
  };

  // Get unique locations, dates, categories, and towns from events
  const uniqueLocations = [...new Set(events.map(event => event.Location))];
  const uniqueDates = [...new Set(events.map(event => event.Date))];
  const uniqueCategories = [...new Set(events.map(event => event.Category))];
  const uniqueTowns = [...new Set(events.map(event => event.selectedTown))];
  
  // ... Rest of the code
  

  useEffect(() => {
    // Update the filtered events when filters change
    const filtered = filterEvents();
    setFilteredEvents(filtered);

    // Update the unique event IDs set
    const newEventIds = new Set();
    filtered.forEach(event => newEventIds.add(event._id));
    setUniqueEventIds(newEventIds);
  }, [selectedLocation, selectedDate, selectedCategory, searchQuery, selectedTown]);

  const convertUrl = (document) => {
    const startsWithHttpOrHttps = /^https?:/.test(document);
    if (startsWithHttpOrHttps) {
      return document;
    } else {
      return `${process.env.REACT_APP_BACKEND}/uploads/events/${document}`;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const location = useLocation(); // Get the current URL location

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const eventId = searchParams.get('eventId');

    if (eventId) {
      const selectedEvent = events.find(event => event._id === eventId);
      if (selectedEvent) {
        setSelectedEvent(selectedEvent);
      }
    }
  }, [location, events]);
  return (
    <div className="App">
      <div className="filters">
        <select
          value={selectedLocation}
          onChange={e => setSelectedLocation(e.target.value)}
        >
          <option value="">All Locations</option>
          {uniqueLocations.map(location => (
            <option key={location} value={location}>
              <i className="fa fa-map-marker"></i> {location}
            </option>
          ))}
        </select>

        {/* <select
          value={selectedDate}
          onChange={e => setSelectedDate(e.target.value)}
        >
          <option value="">All Dates</option>
          {uniqueDates.map(date => (
            <option key={date} value={date}>
              <i className="fa fa-calendar"></i> {formatDate(date)}
            </option>
          ))}
        </select> */}

        <select
          value={selectedCategory}
          onChange={e => setSelectedCategory(e.target.value)}
        >
          <option value="">All Categories</option>
          {uniqueCategories.map(category => (
            <option key={category} value={category}>
              <i className="fa fa-tag"></i> {category}
            </option>
          ))}
        </select>

        <select
          value={selectedTown}
          onChange={e => setSelectedTown(e.target.value)}
        >
          <option value="">All Towns</option>
          {uniqueTowns.map(town => (
            <option key={town} value={town}>
              <i className="fa fa-map-marker"></i> {town}
            </option>
          ))}
        </select>

        <input
          type="text"
          placeholder="Search by title..."
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </div>
      <div>
        <ul>
          {filteredEvents.map(event => (
            <li key={event._id || event.Title} onClick={() => openModal(event)}>
              <div className="date-box">
                <i className="fa fa-clock-o"></i> {formatDate(event.Date)}
              </div>
              {(event?.document ?? event['Image URL']) ? (
                <img
                  className="card-img"
                  src={convertUrl((event?.document ?? event['Image URL']).replace(/"/g, ''))}
                  alt="Event"
                />
              ) : (
                <img
                  className="card-img"
                  src={Dummy}
                  alt="Event"
                />
              )}
              <div className="event-desc">
                <h2>{event.Title}</h2>
                {event.Location && (
                  <p>
                    <i className="fa fa-map-marker"></i> {event.Location}
                  </p>
                )}
                {event.selectedTown && (
                  <p>
                    <i className="fa fa-map-marker"></i> {event.selectedTown}
                  </p>
                )}

                {event.HowMuch && (
                  <p>
                    <i className="fa fa-money"></i> {event.HowMuch}
                  </p>
                )}
                {event.Category && (
                  <p>
                    <i className="fa fa-tags"></i>{event.Category}
                  </p>
                )}
                {event.Time && (
                  <p>
                    <i className="fa fa-clock-o"></i> {event.Time}
                  </p>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <Modal event={selectedEvent} onClose={closeModal} />

    </div>
  );
}

export default Home;
